import request from '../utils/request'
import config from './config'

// 机构补贴-基础数据详情
export function basedatadetails(params) {
  return request({
    url: `${config.CIVIL_HOST}/administrationSysSubsidy/details`,
    method: 'get',
    params,
  })
}

// 机构补贴-机构补贴新增、修改
export function save(data) {
  return request({
    url: `${config.CIVIL_HOST}/administrationSysSubsidy/save`,
    method: 'post',
    data,
  })
}


// 机构补贴-申请列表
export function list(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceSubsidyApplication/list`,
    method: 'get',
    params,
  })
}

// 机构补贴-机构申请服务补贴详情
export function details(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceSubsidyApplication/details`,
    method: 'get',
    params,
  })
}

// 审核操作
export function examine(data) {
  return request({
    url: `${config.CIVIL_HOST}/pensionServiceSubsidyApplication/examine`,
    method: 'post',
    data,
  })
}

/*入住老人信息-列表*/
export function elderInfoList(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesElderlyRegistration/list`,
    method: 'get',
    params,
  })
}

/*入住老人信息-机构列表*/
export function examineSysList(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesElderlyRegistration/examineSysList`,
    method: 'get',
    params,
  })
}


/*入住老人信息详情-基本资料*/
export function basicInformation(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesElderlyRegistration/basicInformation`,
    method: 'get',
    params,
  })
}


// 入住资料
export function moveInto(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesElderlyRegistration/moveInto`,
    method: 'get',
    params,
  })
}

//紧急联系人
export function emergencyContacts(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesElderlyRegistration/emergencyContacts`,
    method: 'get',
    params,
  })
}


// 老人统计申请列表
export function elderlyStatisticsSummary(params) {
  return request({
    url: `${config.CIVIL_HOST}/statisticalReport/elderlyStatisticsSummary`,
    method: 'get',
    params,
  })
}


// 导出老人统计月汇总
export function exportElderlyStatistics(params) {
  return request({
    url: `${config.CIVIL_HOST}/statisticalReport/exportElderlyStatistics`,
    method: 'get',
    responseType: 'blob',
    params,
  })
}


// 返回组织列表
export function getOrganizationList(params) {
  return request({
    url: `${config.CIVIL_HOST}/statisticalReport/getOrganizationList`,
    method: 'get',
    params,
  })
}

export function getOrganizationListWithSubsidy(params) {
  return request({
    url: `${config.CIVIL_HOST}/statisticalReport/getOrganizationListWithSubsidy`,
    method: 'get',
    params,
  })
}


// 老人审核列表
export function registrationList(data) {
  return request({
    url: `${config.CIVIL_HOST}/archivesRegistrationAuditRecord/registrationList`,
    method: 'post',
    data,
  })
}


// 入住机构列表(审核通过的)
export function institutionsList(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesRegistrationAuditRecord/institutionsList`,
    method: 'get',
    params,
  })
}


// 批量审核操作
export function batchAudit(data) {
  return request({
    url: `${config.CIVIL_HOST}/archivesRegistrationAuditRecord/batchAudit`,
    method: 'post',
    data,
  })
}


// 查看老人登记表详情
export function oldDetails(params) {
  return request({
    url: `${config.CIVIL_HOST}/archivesRegistrationAuditRecord/details`,
    method: 'get',
    params,
  })
}


// 老人审核列表导出
export function oldExport(data) {
  return request({
    url: `${config.CIVIL_HOST}/archivesRegistrationAuditRecord/export`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}


// 单个审核操作
export function singleAudit(data) {
  return request({
    url: `${config.CIVIL_HOST}/archivesRegistrationAuditRecord/singleAudit`,
    method: 'post',
    data,
  })
}



// 返回服务补贴报表
export function getServiceSubsidyReport(params) {
  return request({
    url: `${config.CIVIL_HOST}/statisticalReport/getServiceSubsidyReport`,
    method: 'get',
    params,
  })
}



// 导出服务补贴报表
export function exportServiceSubsidyStatic(params) {
  return request({
    url: `${config.CIVIL_HOST}/statisticalReport/exportServiceSubsidyStatic`,
    method: 'get',
    responseType: 'blob',
    params,
  })
}
// 数据字典
export function getDictList(params) {
  return request({
    url: `${config.CIVIL_HOST}/pensionDictInfo/getDictList`,
    method: 'get',
    params,
  })
}


