<template>
  <div class="page">
    <div class="submitForm">
      <el-form ref="form" :model="submitForm" label-position="top" label-width="140px">
        <el-row>
          <el-descriptions title="用户信息" :column="2">
            <el-descriptions-item label="姓名">{{submitForm.userName || '--'}}</el-descriptions-item>
            <el-descriptions-item style="margin-top: 30px" label="联系电话">{{submitForm.phone || '--'}}</el-descriptions-item>
            <el-descriptions-item label="出生日期">{{submitForm.birthday || '--'}}</el-descriptions-item>
            <el-descriptions-item label="年龄">{{submitForm.age || '--'}}</el-descriptions-item>
            <el-descriptions-item label="性别">{{['女', '男', '未知'][submitForm.sex*1]}}</el-descriptions-item>
            <el-descriptions-item label="民族">{{submitForm.nation || '--'}}</el-descriptions-item>
            <el-descriptions-item label="学历">{{ getTextName(education,submitForm.cultureDegree)}}</el-descriptions-item>
            <el-descriptions-item label="老人类型">{{['', '本区老人', '非本区老人'][submitForm.mzeeType*1]}}</el-descriptions-item>
          </el-descriptions>
        </el-row>
        <el-row>
          <div style="font-size: 16px;font-weight: bold;margin: 20px 0">家属信息</div>
          <el-table
            :data="submitForm.familyResultList"
            stripe
            border
            :header-cell-style="headerCellStyle"
            style="width: 100%;">
            <template slot="empty">
              <IsEmpty />
            </template>
            <el-table-column type="index" label="序号" align="center" width="80" />
            <el-table-column
              prop="name"
              align="center"
              label="姓名">
            </el-table-column>
            <el-table-column
              prop="sex"
              align="center"
              label="性别">
              <template slot-scope="scope">
                <span>{{['女', '男'][scope.row.sex*1]}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="relationship"
              align="center"
              label="关系">
            </el-table-column>
            <el-table-column
              prop="telephoneNumber"
              align="center"
              label="电话号码">
            </el-table-column>
            <el-table-column
              prop="phone"
              align="center"
              label="手机号码">
            </el-table-column>
            <el-table-column
              prop="homeAddress"
              align="center"
              label="家庭地址">
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-descriptions title="身份证" :column="2">
            <el-descriptions-item label="证件类型">{{getTextName(CardType,submitForm.documentType)}}</el-descriptions-item>
            <el-descriptions-item style="margin-top: 30px" label="证件号码">{{submitForm.cardId || '--'}}</el-descriptions-item>
          </el-descriptions>
          <div class="imgs-box">
            <div v-if="submitForm.cardFrontImage" class="imgs">
              <img :src="submitForm.cardFrontImage" width="200" height="200" alt="">
              <span>身份证-人像面</span>
            </div>
            <div v-if="submitForm.cardBackImage" class="imgs">
              <img :src="submitForm.cardBackImage"  width="200" height="200" alt="">
              <span>身份证-国徽面</span>
            </div>
          </div>
        </el-row>
        <el-row style="margin-top: 35px">
          <el-descriptions title="户籍信息" :column="1">
            <el-descriptions-item label="所属区域">
               <span v-if="submitForm.province || submitForm.city || submitForm.area || submitForm.community || submitForm.street">
                   {{submitForm.province}}
                  {{submitForm.city}}
                  {{submitForm.area}}
                   {{submitForm.street}}
                  {{submitForm.community}}

                 </span>
              <span v-else>--</span>
            </el-descriptions-item>
            <el-descriptions-item label="户籍地址">{{submitForm.registeredResidence || '--'}}</el-descriptions-item>
            <el-descriptions-item label="常住地址">{{submitForm.homeAddress || '--'}}</el-descriptions-item>
          </el-descriptions>
          <div class="imgs-box">
            <div v-if="submitForm.residenceFirstImage" class="imgs">
              <img :src="submitForm.residenceFirstImage" width="200" height="200" alt="">
              <span>户口簿-户主页(首页)</span>
            </div>
            <div v-if="submitForm.residenceOneselfImage" class="imgs">
              <img :src="submitForm.residenceOneselfImage" width="200" height="200" alt="">
              <span>户口簿-本人页</span>
            </div>
            <div v-if="submitForm.residenceFluctuateImage" class="imgs">
              <img :src="submitForm.residenceFluctuateImage" width="200" height="200" alt="">
              <span>户口簿-增减页</span>
            </div>
          </div>
        </el-row>
        <el-row style="margin-top: 35px">
          <el-descriptions title="缴费收据" :column="1"/>
          <div v-if="submitForm.receiptImage" class="imgs-box">
            <div v-for="(item, index) in submitForm.receiptImage.split(',')" :key="index"  class="imgs">
              <img :src="item" alt="">
            </div>
          </div>
          <span v-else>暂无图片</span>
        </el-row>
        <el-row style="margin-top: 35px">
          <el-descriptions title="入住协议" :column="1"/>
          <div class="imgs-box">
            <div v-if="submitForm.agreementFirstImage" class="imgs">
              <img :src="submitForm.agreementFirstImage" alt="">
              <span>入住协议-首页</span>
            </div>
            <div v-if="submitForm.agreementTimeImage" class="imgs">
              <img :src="submitForm.agreementTimeImage" alt="">
              <span>入住协议-入住时间页</span>
            </div>
            <div v-if="submitForm.agreementSignImage" class="imgs">
              <img :src="submitForm.agreementSignImage" alt="">
              <span>入住协议-签字页</span>
            </div>
          </div>
        </el-row>
        <el-row style="margin-top: 35px">
          <el-descriptions title="评估资料" :column="1"/>
          <div v-if="submitForm.evaluationDataImage" class="imgs-box">
            <div v-for="(item, index) in submitForm.evaluationDataImage.split(',')" :key="index" class="imgs">
              <img :src="item" alt="">
            </div>
          </div>
          <span v-else>暂无图片</span>
        </el-row>
        <el-row style="margin-top: 35px">
          <el-descriptions title="体检资料" :column="1"/>
          <div v-if="submitForm.checkupImage" class="imgs-box">
            <div v-for="(item, index) in submitForm.checkupImage.split(',')" :key="index" class="imgs">
              <img :src="item" alt="">
            </div>
          </div>
          <span v-else>暂无图片</span>
        </el-row>
        <el-row style="margin-top: 35px">
          <el-descriptions title="银行卡信息" :column="2">
            <el-descriptions-item label="银行卡账号">{{submitForm.bankCard || '--'}}</el-descriptions-item>
            <el-descriptions-item label="开户行">{{submitForm.bankDeposit || '--'}}</el-descriptions-item>
            <el-descriptions-item label="持卡人姓名">{{submitForm.bankUserName || '--'}}</el-descriptions-item>
            <el-descriptions-item label="持卡人与本人关系">{{submitForm.bankRelationship || '--'}}</el-descriptions-item>
          </el-descriptions>
          <div v-if="submitForm.bankImage" class="imgs-box">
            <div class="imgs">
              <img :src="submitForm.bankImage" alt="">
              <span>银行卡-正面</span>
            </div>
          </div>
        </el-row>

        <el-row style="margin-top: 35px">
          <div class="title" style="margin-bottom: 20px;">审核记录</div>
          <el-col :span="18" >
            <el-table :data="submitForm.auditRecordVoList" border  style="width: 100%" :header-cell-style="headerCellStyle">
              <template slot="empty">
                <IsEmpty />
              </template>
              <el-table-column type="index" label="序号" align="center" width="60" />
              <el-table-column prop="type" align="center" label="操作类型" >
                <template v-slot="scope">
                  <span>{{['提交', '审核'][scope.row.type*1]}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="state" align="center" label="审核状态">
                <template v-slot="scope">
                  <span>{{['通过', '不通过'][scope.row.state*1]}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" align="center" label="操作时间" width="170"/>
              <el-table-column prop="governmentName" align="center" label="操作单位" width="200"/>
              <el-table-column prop="examineUserName" align="center" label="操作人">
                <template v-slot="scope">
                  <span>{{scope.row.examineUserName || scope.row.governmentName}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="refusalCause" align="center" label="不通过原因" width="200"/>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn">
        <el-button style="width:120px;margin-right:30px" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { oldDetails, getDictList } from "@/api/institutionalSubsidies"
  import { getChildrenDict } from "@/api/unitManage"
  export default {
    name: "elderlyFormInfoView",
    data() {
      return {
        CardType: [],
        education: [],
        rowId: '',
        submitForm: {},
      }
    },
    mounted() {

      this.rowId = this.$route.query.id
      this.oldDetailsData()
      this.getEducation()
      this.getCardType()
    },
    beforeRouteEnter(to,from,next) {
      to.meta.title = '查看'
      next()
    },
    methods: {
      getTextName(list,id) {
        let arr = list.filter(item => item.id === id)
        if(arr.length) {
          return arr[0].parameter
        }else {
          return  '--'
        }
      },
      async getEducation() {
        const res = await getDictList({dictType: 16})
        this.education = res.data
      },
      async getCardType() {
        const res = await getDictList({dictType: 17})
        this.CardType = res.data
      },
      async oldDetailsData() {
        const res = await oldDetails({id:this.rowId})
        this.submitForm = res.data
        if(res.data.provinceId) {
          const res1 = await getChildrenDict() //省
          res1.data.forEach(item1 => {
            if(item1.organizationId == res.data.provinceId) this.submitForm.province = item1.organizationName
          })
          const res2 = await getChildrenDict({pid: res.data.provinceId}) //市
          res2.data.forEach(item2 => {
            if(item2.organizationId == res.data.cityId) this.submitForm.city = item2.organizationName
          })
          if(res.data.cityId) {
            const res3 = await getChildrenDict({pid: res.data.cityId}) //区
            res3.data.forEach(item3 => {
              if(item3.organizationId == res.data.areaId) this.submitForm.area = item3.organizationName
            })

            const res5 = await getChildrenDict({pid: res.data.areaId}) //社区街道
            res5.data.forEach(item5 => {
              if(item5.organizationId == res.data.streetId) this.submitForm.street = item5.organizationName
            })

            const res4 = await getChildrenDict({pid: res.data.streetId}) //乡镇街道
            res4.data.forEach(item4 => {
              if(item4.organizationId == res.data.communityId) this.submitForm.community = item4.organizationName
            })



          }
        }
      },
      handleClose() {
        this.dialogVisible = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  /*  /deep/ .el-descriptions-item__content {
      width: 100%;
    }*/
  .page {
    background-color: #ffffff;
    padding: 20px 30px;
    min-height: calc(100vh - 145px);
    box-sizing: border-box;
    .submitForm {
      margin-top: 10px;
      width: 80%;
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .tip {
        color: #7f7f7f;
        margin-bottom: 10px;
      }
      .tip_bottom {
        line-height: 20px;
      }
      .btn {
        margin-top: 30px;
        margin-left: 40%;
      }
      .imgs-box {
        display: flex;
        margin-top: 20px;
        .imgs{
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 250px;
          box-sizing: border-box;
          img {
            width: 200px;
            height: 200px;
            /*object-fit: cover;*/
          }
          span {
            display: inline-block;
            color: #606266;
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  span {
    font-size: 14px !important;
  }
</style>
